import React from "react";
import { graphql, Link } from "gatsby"
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Container, Typography, Box, Link as MuiLink } from "@material-ui/core";

import Layout from '../components/Layout';

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      root: {
        padding: theme.spacing(2, 0),

        '& p, li': {
          fontSize: 16,
        },
        '& a': {
          textDecoration: 'underline',
        },
        
      },
    }
  ),
);

interface Props {
  data: {
    site: {
      siteMetadata: {
        shortName: string;
      }
    }
  }
}

const PrivacyPage: React.FC<Props> = ({ data }) => {
  const classes = useStyles();
  const name = data.site.siteMetadata.shortName;

  return (
    <Layout contentClassName={classes.root} title="Privacy Policy">
      <Container maxWidth="md">
        <Typography align="center" variant="h4">Privacy Policy</Typography>
        <br />
        <strong>Last updated: 1/1/2020.</strong>
        <br />
        <p>This Privacy Notice (“Notice”) applies to this website or mobile application and the websites or mobile applications of {name}, Inc., and its subsidiaries and affiliates (collectively “we”, “us” or “our”) that display this Notice (each a “Site” and collectively, the “Sites”). By visiting the Sites, you are consenting to our collection, use, disclosure, retention, and protection of information about you and devices you use as described in this Notice.</p>
        <p>Our Sites are not directed to children under the age of 18. We do not knowingly collect personal information from children under age 18. This Notice does not apply to anonymized or aggregated data that does not allow us or third parties to identify or contact you.</p>

        <Box>
          <Typography variant="h6">1. What Information Does {name} Gather?</Typography>
          <p><strong>Information You Give Us.</strong> We may collect and retain any information from you or your devices provided to us when you visit a Site, including when you:</p>
          <ul>
            <li>use our service or mobile application;</li>
            <li>register for an account;</li>
            <li>communicate with us, such as to provide feedback, request support, or ask for additional information; and</li>
            <li>subscribe to content we offer, such as newsletters, alerts, etc.</li>
          </ul>

          <p>We may collect information such as your first and last name, home or other physical address, email address, zip code, telephone number, your automobile’s make, model, trim, model year, mileage, color, options, or information about your interactions with us or others, and other information.</p>
          <p><strong>Social Media.</strong> You may use social networks or other online services to access our Sites. When you do so, information from those services may be made available to us. By associating a social network account with our Sites, you agree that we may access and retain that information in accordance with the policies of the social network or other online service and this Notice. For example, we may be able to access account or profile information that you have provided to the social network or information about your interactions with the social network to make information available to us (such as when commenting on a blog post or using a sign-on service, such as Facebook Connect).</p>
          <p><strong>Automatically Collected Information.</strong> We may collect information automatically when you visit our Sites or use our mobile applications, such as:</p>

          <ul>
            <li>your IP address; the type of browser, devices and operating systems you use;</li>
            <li>identifiers associated with the device(s) you use to access our Sites;</li>
            <li>the pages you visit, vehicles you view or configure, and the features you use, including dates and times;</li>
            <li>if you navigated from or navigate to another website, the address of that website; and</li>
            <li>information regarding your internet service provider.</li>
          </ul>
          <p>In addition, we may collect information about your activities on our Sites via first and third-party cookies, clear GIFs or web beacons, or through other identifiers or technologies, including similar technologies as they may evolve over time. We refer to these technologies collectively as Metrics Tools.</p>
          <p>We may allow third parties to use Metrics Tools on our Sites. The information collected by Metrics Tools may be shared with and used by us, by others acting on our behalf, or by third parties subject to their own privacy policies. Information collected by Metrics Tools may be used on this Site or on other websites or services, including those that may not be operated by us.</p>
          <p><strong>Email.</strong> We may collect information regarding the effectiveness of our email and other communications with you. For example, we may know if you follow a link in an email we send to you.</p>

          <p><strong>Information from Other Sources.</strong> We may obtain information about you from affiliates, partners, automobile dealers and other third parties. This information may include information about your use of this Site or our services, your use of other websites, your interactions with or purchases from automobile dealers, your interests and preferences and other information about you or your household. We may combine the information we obtain from third parties with information that we or our affiliates have collected about you.</p>
        </Box>

        <Box>
          <Typography variant="h6">2. How Does {name} Use Information About Me?</Typography>
          <p>We and others acting on our behalf may use the information that we collect or receive to operate our business, including our Sites, as described below:</p>
          <p><strong>Operate and Support our Sites and Services.</strong> We use the information that we gather in order to operate our Sites and our services. For example, we may use the information that we collect or receive to provide support and assistance that you request or to diagnose or address technical problems in the operation of our Sites or any of our services. If you establish an account with us, we may use information about you to manage or support your account. We may identify your use of our Sites across devices, and we may use information we collect from you and receive from others to optimize your use of the Sites and our services as you use different devices.</p>
          <p><strong>Improving and Evolving our Services.</strong> We constantly evaluate and improve our Sites and services, including developing new products or services and use the information we gather to do so.</p>
          <p><strong>Advertising and Promotions.</strong> We may use the information we gather to offer, provide, or personalize products and services from us and third parties. For example, we may customize content, advertising, promotions and incentives to reflect your preferences, interests, or prior interactions with us and others.</p>
          <p><strong>Other Contacts.</strong> We may contact you through telephone, text, or chat for other purposes, as permitted by law.</p>
          <p>This Notice only addresses our own information practices. This Notice does not apply to information you share with third parties, including but not limited to dealers and lending institutions, even if we link to those third parties from a Site. These third parties may have their own privacy policies governing their use of information that you can access from their websites. Our services may be offered through third party websites or mobile applications (“Partner Sites”). This Notice does not apply to Partner Sites, unless otherwise indicated on the Partner Site.</p>
          <p>Please note that other parties may collect personally identifiable information about your online activities over time and across different websites when you use our Site.</p>
        </Box>
        
        <Box>
          <Typography variant="h6">3. When Does {name} Share Information?</Typography>
          <p>We only share information about you as described or permitted by this Notice, unless you consent to other sharing. We may share information about you in the following ways:</p>
          <p><strong>Corporate Affiliates.</strong> We may share information with our corporate affiliates for their everyday business purposes, to provide services or to perform marketing. We may also participate in joint ventures with others and we may share information as part of that joint venture.</p>
          <p><strong>Partner Sites.</strong> We may share the information we gather with Partner Sites (and the owners of those sites). For example, we may share information for determining or verifying our economic rights and obligations.</p>
          <p><strong>Service Providers.</strong> We may share information with third party service providers who help us operate our business or provide services on our behalf, including third parties who help us operate our Sites. These service providers include analytics companies and advertising networks that provide Metrics Tools on our Sites.</p>
          <p><strong>Promotional.</strong> We may share information with third parties to help us promote our products and services, or to help us customize advertisements, offers, or other communications to you.</p>
          <p><strong>Business Transfer.</strong> We may share information we have collected from you in connection with the sale or merger of our business or the transfer of assets.</p>
          <p><strong>Protection of Ourselves and Others.</strong> We may use and share the information we gather to enforce our rights and protect our property and those of our affiliates, customers and members of the public, to comply with applicable law, legal process, legal advice and for preventing fraud, theft, and injury to you, us or others.</p>
        </Box>
        <Box>
          <Typography variant="h6">4. What Choices Do I Have Regarding My Information?</Typography>
          <p>You may limit and control the information provided to us in several ways. You may not be able to use all features of our Sites if you limit the information you share with us.</p>
          <p>For example, you can choose not to access our Sites through your social media account. You may also be able to limit the information provided to us by third party social media providers by altering your privacy settings with those providers. You may unsubscribe from promotional emails from us by following the unsubscribe link included in each such email.</p>
          <p>Your browser and your device may provide you the option to limit the use of cookies or other Metrics Tools. You should consult documentation for your browser or device for more information. Your mobile device may have settings that allow you to prevent sharing geolocation information with us. You should consult your mobile device’s settings or help documentation for more information about exercising these options.</p>
          <p>{name} uses Google Analytics, which helps {name} better understand its audience and target communications and advertisements based on demographic or interest-based information. You may learn more about opting out of certain Google advertising initiatives <MuiLink href="https://support.google.com/ads/answer/2662922?hl=en" target="_blank" rel="noopener noreferrer">here</MuiLink> and <MuiLink href="https://tools.google.com/dlpage/gaoptout/" target="_blank" rel="noopener noreferrer">here</MuiLink>. You may also be able to opt out of certain targeted behavioral advertising via the Network Advertising Initiative’s <MuiLink href="http://optout.networkadvertising.org/?c=1#!/" target="_blank" rel="noopener noreferrer">opt-out</MuiLink>.</p>
          <p>Please note that you may still receive advertising even after opting out, but that advertising may not be tailored to you or your interests.</p>
        </Box>
        <Box>
          <Typography variant="h6">5. How Can I Access and Update My Information?</Typography>
          <p>If you are a registered user, you may access, update and change certain information we have collected about you by accessing the “My Account” section after signing into your account.</p>
        </Box>
        <Box>
          <Typography variant="h6">6. How Does {name} Address California Privacy Rights?</Typography>
          <p>This part of our Notice applies to consumers who reside in the State of California.</p>
          <p><strong>Personal Information We Collect about California Consumers</strong></p>

          <p>We describe the Personal Information we have collected from consumers in the twelve (12) months preceding the effective date of this Notice in the part of our Notice titled, “What Information Does {name} Gather?”. The information we collect includes the following:</p>
          <ul>
            <li>Identifiers such as name, address, e-mail address, phone number and other identifiers that we describe above.</li>
            <li>The following categories of Personal Information are described in California Civil Code § 1798.80(e): (1) the Personal Information listed in the preceding bullet point as “identifiers,” (2) vehicle identification numbers (when linked to identified persons), vehicle information such as model and year, vehicle registration information, and license plate numbers, (3) information you provide or that we record in connection with customer support calls or complaints, (4) demographic information such as age range and gender, and (5) the other information that identifies, relates to, describes, or is capable of being associated with, a particular individual that we describe in “What Information Does {name} Gather?”.</li>
            <li>Characteristics of protected classifications under California or federal law, such as military service, sex or age range. Commercial information, including information about records of personal property, orders, purchases, or usage of services in connection with {name} Services.</li>
            <li>Internet or other electronic network activity information, including, but not limited to, browsing history, search history, usage of, and information regarding your interaction with our Sites.</li>
            <li>Geolocation data. When you use our Sites, it may access, collect, monitor, and/or remotely store session and geolocation information from your device to connect you with dealers in your area. Geolocation information includes data such as your device’s physical location and may include GPS-based, WiFi-based or cell-based location information.</li>
            <li>Audio information may be collected when you place a call with {name}’s customer service or accounting centers for quality control purposes.</li>
            <li>Inferences drawn from (1) the information we collect when you utilize our Sites, and (2) information about {name} user preferences and behavior, which we use for product and service enhancement and optimization purposes.</li>
          </ul>

          <p><strong>Your California Privacy Rights</strong></p>
          <p>If you are a California consumer, you have the following rights. We will honor requests received to the extent required by applicable law and within the time provided by law.</p>
          <Box ml={2}>
            <p><strong>(a) Right to Access and Information regarding Personal Information.</strong> You have the right to request that we disclose to you the categories and specific pieces of Personal Information we have collected about you. Specifically, you have the right to request that we disclose the following to you, in each case in the twelve-month period preceding your request:</p>
            <ul>
              <li>the categories of Personal Information we have collected about you;</li>
              <li>the categories of sources from which the Personal Information is collected;</li>
              <li>our business or commercial purpose for collecting or selling Personal Information;</li>
              <li>the categories of third parties with whom we share Personal Information;</li>
              <li>the specific pieces of information we have collected about you;</li>
              <li>the categories of Personal Information about you that we disclosed for a business purpose.</li>
            </ul>
          </Box>
          <p>We will deliver Personal Information that we are required by law to disclose to you in the manner required by law within 45 days after receipt of a verifiable request, unless we notify you that we require additional time to respond, in which case we will respond within such additional period of time required by law. We may deliver the Personal Information to you through your account, if you maintain an account with {name}, or, if not, electronically. If electronically, then we will deliver the information in a portable and, to the extent technically feasible, in a readily useable format that allows you to transmit the information from one entity to another without hindrance.</p>
          <Box ml={2}>
            <p><strong>(b) Right to Deletion of Personal Information.</strong> You have the right to request that we delete Personal Information about you that we have collected from you.</p>
            <p><strong>(c) Right to Opt Out.</strong> You have the right to opt out of the disclosure of Personal Information about you to third parties for monetary or other valuable consideration. To opt-out, please <MuiLink component={Link} to="/contact-us">Contact Us</MuiLink></p>
            <p><strong>(d) Right to Non-Discrimination.</strong> We may not discriminate against you because of your exercise of any of the foregoing rights, or any other rights under the California Consumer Privacy Act, including by:</p>
            <ul>
              <li>denying you goods or services;</li>
              <li>charging different prices or rates for goods or services, including through the use of discounts or other benefits or imposing penalties;</li>
              <li>providing you a different level or quality of goods or services; or</li>
              <li>suggesting that you will receive a different price or rate for goods or services or a different level or quality of goods or services.</li>
            </ul>
            <p>We may, however, charge different prices or rates, or provide a different level or quality of goods or services, if that difference is reasonably related to the value provided to {name} by your Personal Information.</p>
          </Box>

          <p><strong>Requests to Exercise Your Rights</strong></p>
          <p>You may request to exercise the foregoing rights by <MuiLink component={Link} to="/contact-us">contacting us</MuiLink></p>
          <p>You may designate an authorized agent to make a request on your behalf. Please note that we may take steps to verify your identity before granting you access to information or acting on your request to exercise your rights, as required or permitted by law. We may limit our response to your exercise of the above rights as permitted by law.</p>
          <p><strong>Data Sharing for Direct Marketing Purposes</strong></p>
          <p>Residents of the State of California may request a list of all third parties to which we have disclosed certain personal information (as defined by California law) during the preceding year for those third parties’ direct marketing purposes. If you are a California resident and want such a list, please <MuiLink component={Link} to="/contact-us">contact us</MuiLink>.</p>

          <p><strong>Do Not Track Notice</strong></p>
          <p>At this time, there is no worldwide uniform or consistent industry standard or definition for responding to, processing, or communicating Do Not Track signals. Thus, like many other websites and online services, our Sites are currently unable to respond to Do Not Track Signals.</p>
        </Box>
        
        <Box>
          <Typography variant="h6">7. How Does {name} Handle Privacy Disputes?</Typography>
          <p>By using this Site, you agree that any dispute arising out of or relating to the Sites, the Sites’ content or the services or materials made available on the Sites, or regarding information collected or shared about you, is subject to the Choice of Law, Venue, and Class Action Waiver provisions in our Terms of Service.</p>
        </Box>
        
        <Box>
          <Typography variant="h6">8. How Does {name} Protect the Security of the Personal Information it Collects?</Typography>
          <p>We use administrative, technical, and physical security designed to safeguard personal information in our possession. We cannot guarantee the security of the information that we collect and store. If you believe that information about you has been subject to unauthorized disclosure, please <MuiLink component={Link} to="/contact-us">contact us</MuiLink>.</p>
        </Box>
        <Box>
          <Typography variant="h6">9. How Does {name} Provide Updates to this Notice?</Typography>
          <p>When we make material changes to this Notice, we will update this web page and change the Effective Date listed above.</p>
        </Box>
        <Box>
          <Typography variant="h6">10. How Can {name} be Contacted Regarding Privacy Issues?</Typography>
          <p>You can contact us with questions or comments about our privacy practices or this Notice by using our <MuiLink component={Link} to="/contact-us">contact form</MuiLink>.</p>
        </Box>

      </Container>
    </Layout>
  );
};

export default PrivacyPage;

export const query = graphql`
  query {
    site {
      siteMetadata {
        shortName
      }
    }
  }
`